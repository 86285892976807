import React, { useMemo } from 'react'
import { Row, Col } from 'antd'
import { Form, FormItem } from '@/components/EnquireForm/Form'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { formList } from './config'

const isRequreText = (text?: string, flag?: boolean) => {
  return (
    <Box
      sx={{
        fontWeight: 500,
        span: {
          color: '#FF0000',
        },
      }}
    >
      {text}
      {flag ? <span> *</span> : null}
    </Box>
  )
}

const CareersForm = ({ careerDetails, countryAreaCode, form }) => {
  const theme = useTheme()
  const { direction } = theme
  const isPc = useMediaQuery('@media (min-width: 800px)')

  const cityList = useMemo(() => {
    return (
      careerDetails?.city?.split(',')?.map((item) => ({
        key: item,
        value: item,
      })) || []
    )
  }, [careerDetails])

  const initialValues = useMemo(() => {
    return {
      city: cityList?.length === 1 ? cityList[0].value : undefined,
    }
  }, [cityList])

  const { formCustomList } = useMemo(() => {
    return formList(
      {
        countryAreaCode,
        cityList,
        title: careerDetails?.tittle,
      },
      isPc,
    )
  }, [countryAreaCode, cityList, isPc, careerDetails])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        button: {
          width: '160px',
          height: '48px',
          borderRadius: '24px',
          ':nth-of-type(2)': {
            background: '#005EDF',
            color: '#fff',
            marginLeft: '20px',
          },
        },
        '.label': {
          minHeight: 22,
          fontSize: [14],
          borderRadius: direction === 'rtl' ? '0 8px 8px 0' : '8px 0 0 8px',
          color: '#5B5C73',
          fontWeight: 500,
          textAlign: 'left',
          marginBottom: '6px',
          padding: '0 10px',
        },
        '.radio-group': {
          display: 'flex',
          flexDirection: 'column',
          fontSize: 14,
          lineHeight: 22,
        },
        '.ant-select-selector': {
          height: '46px !important',
          background: '#f6f8fb !important',
          border: 'none !important',
          alignItems: 'center !important',
          borderRadius: '4px !important',
        },
        '.ant-form-item-has-error': {
          '.ant-form-item-control-input-content': {
            borderRadius: '4px !important',
            border: '1px solid #FF0008 !important',
          },
        },
        '.ant-input,.ant-input-number': {
          height: '46px !important',
          background: '#f6f8fb !important',
          border: 'none !important',
          borderRadius: '4px !important',
          '&:focus': {
            background: '#ffffff !important',
            border: '1px solid #095ef7 !important',
          },
          '.ant-input-number-input': {
            height: '46px !important',
          },
        },
        '.ant-btn[disabled]': {
          color: '#A6A6B9 !important',
          background: '#f6f8fb !important',
        },
        '.ant-upload,.ant-upload-select,.ant-upload-select-text': {
          display: 'block !important',
          height: '48px',
          '.ant-btn,.ant-btn-default': {
            width: '100%',
            marginLeft: 0,
            borderRadius: '4px',
            backgroundColor: '#F6F8FB',
            border: 'none',
            fontWeight: 500,
            color: '#5B5C73',
          },
        },
        '.ant-upload-list-text-container': {
          height: 'auto',
          borderBottom: '1px solid #E1E4EB',
          padding: '20px 0',
        },
        '.ant-upload-list-item-progress': {
          paddingRight: '26px',
          '.ant-progress-text': {
            color: '#426EFF',
            marginLeft: '16px',
          },
          '.ant-progress-outer': {
            width: 'calc(100% - 16px)',
          },
        },
        '.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name': {
          color: '#797E8F !important',
        },
        '.ant-upload-list-item-error': {
          '.ant-upload-list-item-name': {
            ':before': {
              content: "'[导入失败]'",
              width: 'max-content',
              color: 'red',
              marginRight: '5px',
            },
          },
        },
        '.ant-upload-list-item-name': {
          display: 'block',
          width: '50px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon': {
          display: 'block',
          color: '#797E8F',
        },
        '.ant-upload-list-item-card-actions-btn': {
          opacity: 1,
        },
        '.ant-upload-list-item-uploading': {
          '.ant-upload-list-item-card-actions-btn': {
            opacity: '0 !important',
          },
        },
        '.ant-radio-wrapper': {
          marginTop: '20px',
          fontWeight: '500',
        },
        'div[title="Phone Number"]': {
          '.ant-select-selection-item': {
            fontWeight: '500',
            fontSize: '16px',
          },
          '.ant-col': {
            paddingRight: '0px !important',
          },
        },
        '.ant-row': {
          '& > div:nth-of-type(5)': {
            '.label': {
              whiteSpace: 'nowrap',
            },
          },
          '& > div:nth-of-type(6)': {
            '.ant-col': {
              paddingLeft: '0px !important',
            },
          },
        },
        '.ant-select-selection-search-input': {
          height: '46px !important',
        },
        '.ant-form-item-control': {
          padding: '0 10px !important',
        },
      }}
    >
      <Form form={form} initialValues={initialValues} name='form_in_modal'>
        <Row>
          {formCustomList.map((formItem) => (
            <Col
              key={formItem.name}
              span={formItem.span}
              offset={formItem.showSpanOffset ?? 0}
              className='col-span'
            >
              <div className='label'>
                {formItem.noNeedShowIcon
                  ? formItem.title
                  : isRequreText(formItem.title, formItem.required)}
              </div>
              <FormItem {...formItem} key={formItem.name} />
            </Col>
          ))}
        </Row>
      </Form>
    </Box>
  )
}

export default CareersForm
