import { default as React, useEffect, useCallback, useState } from 'react'
import { Row, Col } from 'antd'
import { useRequest } from 'ahooks'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import DocumentMeta from 'react-document-meta'
import { Footer, Header, SEO, useI18next } from '@/components'
import LinkForShare from '@/components/LinkForShare'
import { Box, useMediaQuery } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useLocation } from '@reach/router'
import useDrawer from '@/components/Careers/CareersDrawer'
import { getCareersInfos } from '@/components/Careers/api'
import { APIlistSimilarCareersList } from '@/components/Careers/api'
import { APIqueryBizDictData } from '@/components/EnquireForm/api'
import { formatDate } from '@/components/Careers/CardCommon/utils'
import CardCommon from '../components/Careers/CardCommon'

const RIGHT_COL = {
  xs: 12,
  sm: 12,
  md: 12,
}

const FIELDS_REFLECTION = {
  description: 'Duties & Responsibilities',
  qualification: 'Education Qualification',
  requirement: 'Experience Requirements',
  otherRequirement: 'Other Requirements',
}

const FIELDS_STABLE = Object.keys(FIELDS_REFLECTION)

export const CareersDetail = React.memo((props: any) => {
  const {} = useTranslation()
  const location = useLocation()
  const { navigate } = useI18next()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const imilePost = props.data?.imilePost
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const { enqueueSnackbar } = useSnackbar()
  const [meta] = useState(() => {
    return {
      property: {
        'og:title': imilePost?.searchTitle,
        'og:url': location.href,
        'og:image': imilePost?.detailImageUrl,
        'og:description': 'iMile',

        'twitter:card': 'summary_large_image',
        'twitter:title': imilePost?.searchTitle,
        'twitter:description': '',
        'twitter:image': imilePost?.detailImageUrl,
      },
    }
  })
  const [similarCareersList, setSimilarCareersList] = useState([])
  const [countryAreaCode, setCountryAreaCode] = useState([])
  const [careerDetails, setCareerDetails] = useState<Record<string, any>>({}) // 职业详情数据

  const id = props?.location?.state?.id || location.search.replace(/\?id=/i, '')

  const getCareersDetail = useRequest(getCareersInfos, {
    manual: true,
    onSuccess: (res) => {
      if (res?.resultObject) {
        setCareerDetails(res?.resultObject || {})
      } else if (res?.status === 'success' && !res?.resultObject) {
        enqueueSnackbar('no data')
      } else {
        enqueueSnackbar(res.message || 'error')
      }
    },
    onError: (err) => {
      console.log(1, err)
    },
  })

  const { run: getSimilarCareersList } = useRequest(APIlistSimilarCareersList, {
    manual: true,
    onSuccess: (res) => {
      if (res?.resultObject?.length) {
        setSimilarCareersList(res.resultObject)
      } else {
        setSimilarCareersList([])
      }
    },
    onError: (err) => {
      console.log(1, err)
    },
  })

  const { run: getWebCountryAreaCode } = useRequest(APIqueryBizDictData, {
    manual: true,
    onSuccess: (res) => {
      if (res?.resultObject?.WebCountryAreaCode?.length > 0) {
        const formatData = res.resultObject?.WebCountryAreaCode?.map((item) => ({
          ...item,
          value: item.key,
          label: `${item.value} ${item.key}`,
        }))
        setCountryAreaCode(formatData)
      } else {
        setCountryAreaCode([])
      }
    },
  })

  const { content, handleOpen } = useDrawer({
    countryAreaCode,
    id,
    careerDetails,
    footerLocale: JSON.parse(props.data.footerLocale.data),
  })

  const handleClick = useCallback(() => {
    if (isPc) {
      handleOpen()
    } else {
      navigate(`/applyPage`, {
        state: {
          careerDetails,
          id,
        },
      })
    }
  }, [isPc, careerDetails, id, handleOpen])

  const paragraphShow = useCallback((title, showContent, idx) => {
    return (
      <Box
        key={idx}
        sx={{
          marginTop: '40px',
          div: {
            fontSize: '16px',
            lineHeight: '32px',
            marginBottom: '10px',
            fontWeight: 500,
            color: '#000417',
          },
        }}
      >
        <div>{title && showContent ? FIELDS_REFLECTION[title] : ''}</div>
        {showContent}
      </Box>
    )
  }, [])

  useEffect(() => {
    if (id) {
      getCareersDetail.run({ trimId: id })
      getSimilarCareersList(id)
    }
  }, [id])

  useEffect(() => {
    getWebCountryAreaCode('WebCountryAreaCode')
  }, [])

  useEffect(() => {
    document.querySelector('.banner')?.scrollIntoView({})
  }, [])

  return (
    <>
      <SEO title={imilePost?.tittle} description={imilePost?.tittle} pageLocale={pageLocale} />
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />

      {/* banner*/}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          '.bg-img': {
            height: ['calc(100vh - 55px)', 'calc(100vh - 55px)', '555px', '705px'],
            mt: ['55px', '55px', '90px'],
            width: 1,
          },
        }}
      >
        {isPc ? (
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/careers/banner.png'
            alt='About iMile Delivery'
            className='bg-img'
          />
        ) : (
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/careers/banner-mobile.png'
            alt='About iMile Delivery'
            className='bg-img'
          />
        )}

        {/* <Search
          buttonTxt={commonLocale.search}
          placeHolder={pageLocale.searchTips}
          homebannersx={{
            position: 'absolute',
            bottom: ['104px', 0],
            left: '50%',
            transform: 'translate(-50%)',
            bgcolor: 'unset',
          }}
          callback={(e) => {
            getBlogs({
              titleOrBody: e,
              currentPage: 1,
            })
            setTitleOrBody(e)
          }}
        /> */}
        <Box
          sx={{
            position: 'absolute',
            top: ['60%', '65%', '50%'],
            transform: 'translate(0, -40%)',
            left: ['16px', '80px'],
            textAlign: 'left',
            color: '#fff',
            '.tit': {
              whiteSpace: 'pre-line',
              fontSize: 'var(--slogan-tit-font-size)',
              fontWeight: 800,
            },
            '.desc': {
              whiteSpace: 'pre-line',
              fontSize: 'var(--slogan-desc-font-size)',
            },
          }}
        >
          <h1 className='tit'>{pageLocale.Slogan}</h1>
          <div className='desc'>{pageLocale.SloganDesc}</div>
        </Box>
      </Box>

      <DocumentMeta {...meta} />

      <Row>
        <Col span={isPc ? 16 : 24}>
          <Breadcrumbs
            aria-label='breadcrumb'
            sx={{
              px: 'var(--page-px)',
              maxWidth: 'var(--page-width)',
              my: ['40px'],
              ...(isPc
                ? { px: 'var(--page-px)', maxWidth: 'var(--page-width)', margin: '0 auto' }
                : {}),
            }}
          >
            <Link underline='hover' color='inherit' href='/careers'>
              Careers
            </Link>
            <Typography color='text.primary'>Careers details</Typography>
          </Breadcrumbs>
          {careerDetails?.id ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: isPc ? 'row' : 'column',
                ...(isPc
                  ? { px: 'var(--page-px)', maxWidth: 'var(--page-width)', margin: '0 auto' }
                  : {}),
              }}
            >
              {/* 文章详情 */}
              <Box
                sx={{
                  width: '100%',
                  marginInlineEnd: '30px',
                  ...(!isPc ? { px: 'var(--page-px)', maxWidth: 'var(--page-width)' } : {}),
                }}
              >
                <Box
                  sx={{
                    fontSize: '1.9rem',
                    lineHeight: '52px',
                    color: '#000',
                    fontWeight: '700',
                    marginBottom: '1rem',
                  }}
                >
                  {careerDetails?.tittle || ''}
                </Box>
                {isPc ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '40px',
                      '.text-careers': {
                        maxWidth: '40%',
                        borderRadius: '8px',
                        background: '#005EDF',
                        color: '#fff',
                        padding: '8px 20px',
                      },
                      '.text-fullTime': {
                        maxWidth: '20%',
                        borderRadius: '8px',
                        background: 'rgba(0, 94, 223, 0.2)',
                        color: '#005EDF',
                        padding: '8px 20px',
                        marginLeft: '10px',
                      },
                      '.text-city': {
                        maxWidth: '40%',
                        borderRadius: '8px',
                        background: '#EBEFF5',
                        color: '#000417',
                        padding: '8px 20px',
                        marginLeft: '10px',
                      },
                    }}
                  >
                    <div className='text-careers'>{careerDetails?.category || ''}</div>
                    <div className='text-fullTime'>{careerDetails?.jobTypeName}</div>
                    <div className='text-city'>{`${careerDetails?.city || ''} / ${
                      careerDetails?.country || ''
                    }`}</div>
                  </Box>
                ) : (
                  <Box sx={{ marginBottom: '40px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '20px',
                        '.text-careers': {
                          maxWidth: '70%',
                          borderRadius: '8px',
                          background: '#005EDF',
                          color: '#fff',
                          padding: '8px 20px',
                        },
                        '.text-fullTime': {
                          maxWidth: '30%',
                          borderRadius: '8px',
                          background: 'rgba(0, 94, 223, 0.2)',
                          color: '#005EDF',
                          padding: '8px 20px',
                          marginLeft: '10px',
                        },
                      }}
                    >
                      <div className='text-careers'>{careerDetails?.category || ''}</div>
                      <div className='text-fullTime'>{careerDetails?.jobTypeName}</div>
                    </Box>
                    <Box
                      sx={{
                        '.text-city': {
                          maxWidth: '100%',
                          borderRadius: '8px',
                          background: '#EBEFF5',
                          color: '#000417',
                          padding: '8px 20px',
                        },
                      }}
                    >
                      <div className='text-city'>{`${careerDetails?.city || ''} / ${
                        careerDetails?.country || ''
                      }`}</div>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    fontSize: '16px',
                    color: '#000417',
                  }}
                >
                  {careerDetails?.summary || ''}
                </Box>
                <Box
                  sx={{
                    fontSize: '16px',
                    color: '#000417',
                  }}
                >
                  {FIELDS_STABLE.map((item, idx) =>
                    paragraphShow(item, careerDetails?.[item], idx),
                  )}
                </Box>
                <Box
                  sx={{
                    ...(isPc
                      ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
                      : {}),
                    '.text-time': {
                      fontSize: '16px',
                      color: '#5B5C73',
                    },
                  }}
                >
                  <div className='text-time'>
                    Update Time: {formatDate(careerDetails?.lastUpdDate) || ''}
                  </div>
                  <LinkForShare
                    fillColor='#a6a6b9'
                    size={[20, 24]}
                    mr={['21px', '33px']}
                    anchor={['left']}
                    title={imilePost?.tittle}
                    url={location.href}
                    imageUrl={imilePost?.detailImageUrl}
                  />
                </Box>
                <Box
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <Box
                    sx={
                      {
                        '--height': ['44px', '48px'],
                        a: {
                          display: 'inline-block',
                          height: '60px',
                          width: ['311px', 'fit-content'],
                          minWidth: '180px',
                          textAlign: 'center',
                          lineHeight: '60px',
                          borderRadius: '30px',
                          fontSize: '16px',
                          color: '#fff',
                          backgroundColor: '#095ef7',
                          px: ['52px', '52px', '58px', '72px'],
                          mt: ['40px', '80px'],
                          ...(isPc
                            ? {
                                marginBottom: '120px',
                                marginInlineStart: '50%',
                                transform: 'translate(-50%, 0)',
                              }
                            : {
                                marginBottom: '20px',
                              }),
                        },
                      } as any
                    }
                  >
                    <a onClick={handleClick}>Apply</a>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}
        </Col>
        <Col span={isPc ? 8 : 24}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isPc ? 'row' : 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '40px 0',
              fontSize: '16px',
              color: 'rgba(0, 4, 23, 0.8)',
              height: '28px',
              lineHeight: '28px',
              fontWeight: '500',
            }}
          >
            Similar jobs
          </Box>
          <Box>
            {similarCareersList.length ? (
              <CardCommon list={similarCareersList} col={RIGHT_COL} openBlank={true} />
            ) : null}
          </Box>
        </Col>
      </Row>
      {/* 抽屉hook */}
      {content}
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
})

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["careers"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`

export default CareersDetail
