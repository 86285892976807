import Cookies from 'js-cookie'
import { Shanchu1 } from '@imile/icons'
import { FormItemsProps } from '../CareersDrawer/interface'

const COMMON_INPUT_RULES = {
  required: true,
  message: 'Please enter…',
}

const COMMON_SELECT_RULES = {
  required: true,
  message: 'Please choose…',
}

// 抽屉内表单结构配置
export const formList = ({ countryAreaCode, cityList, title }, isPc?: boolean) => {
  const formCustomList: FormItemsProps[] = [
    {
      type: 'Select',
      name: 'city',
      label: '',
      title: 'City',
      data: cityList,
      required: true,
      optionValue: 'value',
      optionLabel: 'key',
      rules: [COMMON_SELECT_RULES],
      span: isPc ? 12 : 24,
    },
    {
      type: 'Input',
      name: 'email',
      label: '',
      title: 'Email',
      rules: [
        {
          pattern: /\S+\@\S+\.\S+/,
          message: 'please enter vaild email',
        },
        COMMON_INPUT_RULES,
      ],
      required: true,
      span: isPc ? 12 : 24,
    },
    {
      type: 'Input',
      name: 'firstName',
      label: '',
      title: 'First Name',
      required: true,
      rules: [COMMON_INPUT_RULES, { max: 50 }],
      span: isPc ? 12 : 24,
    },
    {
      type: 'Input',
      name: 'lastName',
      label: '',
      title: 'Last Name',
      required: true,
      rules: [COMMON_INPUT_RULES, { max: 50 }],
      span: isPc ? 12 : 24,
    },
    {
      type: 'Select',
      name: 'phonePrefix',
      label: '',
      title: 'Phone Number',
      data: countryAreaCode,
      optionValue: 'value',
      optionLabel: 'label',
      rules: [COMMON_SELECT_RULES],
      showSearch: true,
      required: true,
      span: isPc ? 6 : 8,
    },
    {
      type: 'Input',
      name: 'phone',
      label: '',
      title: '',
      required: true,
      noNeedShowIcon: true,
      rules: [COMMON_INPUT_RULES],
      span: isPc ? 18 : 16,
    },
    {
      type: 'Input',
      name: 'currentLocation',
      label: '',
      title: 'Current Location',
      required: true,
      rules: [COMMON_INPUT_RULES, { max: 40 }],
      span: 24,
    },
    {
      type: 'Input',
      name: 'nationality',
      label: '',
      title: 'Nationality',
      required: true,
      rules: [COMMON_INPUT_RULES, { max: 40 }],
      span: 24,
    },
    {
      type: 'Input',
      name: 'currentJobTitle',
      label: '',
      title: 'Current Job Title',
      required: true,
      rules: [COMMON_INPUT_RULES, { max: 150 }],
      span: 24,
    },
    {
      type: 'Input',
      name: 'currentSalary',
      label: '',
      title: 'Current Salary with Currency Code',
      required: true,
      rules: [COMMON_INPUT_RULES, { max: 25 }],
      span: 24,
    },
    {
      type: 'Input',
      name: 'expectedSalary',
      label: '',
      title: 'Expected Salary with Currency Code',
      required: true,
      rules: [COMMON_INPUT_RULES, { max: 25 }],
      span: 24,
    },
    {
      type: 'Input',
      name: 'noticePeriod',
      label: '',
      title: 'Notice Period(days)',
      required: true,
      rules: [
        COMMON_INPUT_RULES,
        {
          pattern: /^[0-9]{0,3}$/,
          message: 'Please enter a number of no more than 3 characters',
        },
      ],
      span: 24,
    },
    {
      type: 'Upload',
      name: 'file',
      label: '',
      title: 'Cv / Resume',
      showText: 'UPLOAD FILE',
      accept: '.pdf,.doc,.docx',
      action: '/flash/file/upload',
      method: 'POST',
      headers: {
        lang: Cookies.get('EXPRESS_LANG'),
      },
      required: true,
      maxCount: 1,
      span: 24,
      progress: {
        strokeColor: '#426EFF',
        trailColor: '#E8EAF3',
        strokeWidth: 6,
        format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
      },
      showUploadList: {
        showRemoveIcon: true,
        removeIcon: <Shanchu1 sx={{ fontSize: 16 }} />,
      },
      data: { directory: 'web/career' },
      errorInfos: {
        errorText: 'Please provide your CV in pdf or word format, up to 20MB.',
        errorColor: '#FF5300',
        maxSize: 20,
      },
      rules: [
        {
          required: true,
          message: 'Please Upload…',
        },
      ],
    },
    {
      type: 'RadioGroups',
      name: 'isExp',
      label: '',
      title: `Do You Have Relevant Experience As ${title}`,
      data: [
        {
          label: 'YES',
          value: '1',
        },
        {
          label: 'NO',
          value: '0',
        },
      ],
      radioGroupProps: {
        className: 'radio-group',
      },
      required: true,
      rules: [COMMON_SELECT_RULES],
    },
    {
      type: 'RadioGroups',
      name: 'isWorkingAuth',
      label: '',
      title: 'If you have working authorization in country you are applying?',
      data: [
        {
          label: 'YES',
          value: '1',
        },
        {
          label: 'NO',
          value: '0',
        },
      ],
      radioGroupProps: {
        className: 'radio-group',
      },
      required: true,
      rules: [COMMON_SELECT_RULES],
    },
  ]
  return {
    formCustomList,
  }
}
