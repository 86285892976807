import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { Drawer, Button, message } from 'antd'
import { useRequest } from 'ahooks'
import { Box } from '@mui/material'
import { Shanchu4 } from '@imile/icons'
import { Form } from '@/components/EnquireForm/Form'
import { APICareersApply } from '@/components/Careers/api'
import { CustomDrawer } from './interface'
import { formList } from '../CareersForm/config'
import CareersForm from '../CareersForm'

const useDrawer = ({ countryAreaCode, id, careerDetails, footerLocale }): CustomDrawer => {
  const [open, setOpen] = useState<boolean>(false)
  const [form] = Form.useForm()

  const apply = useRequest(APICareersApply, {
    manual: true,
    debounceWait: 500,
    onSuccess: (res) => {
      if (res?.status === 'success') {
        message.config({
          top: 100,
          getContainer: () => document.body,
        })
        message.success('success')
        setOpen(false)
        form.resetFields()
      } else {
        message.error('error')
      }
    },
    onError: (err) => {
      console.log(1, err)
    },
  })

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const cityList = useMemo(() => {
    return (
      careerDetails?.city?.split(',')?.map((item) => ({
        key: item,
        value: item,
      })) || []
    )
  }, [careerDetails])

  const { formCustomList } = useMemo(() => {
    return formList({
      countryAreaCode,
      cityList,
      title: careerDetails?.tittle,
    })
  }, [countryAreaCode, cityList, careerDetails])

  const formKeys = useMemo(() => {
    return formCustomList?.map((item) => item.name)
  }, [formCustomList])

  const handleSubmit = useCallback(() => {
    form
      .validateFields(formKeys || [])
      .then((values) => {
        const { file } = values || {}
        const fileUrl = file?.file?.response?.resultObject
        if (!fileUrl) {
          message.warning('Please upload a valid file')
          return
        }
        const params = {
          ...values,
          careerId: id,
          cvUrl: fileUrl,
        }
        delete params.file
        apply.run(params)
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }, [formKeys, id])

  const extra = useMemo(() => {
    return (
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => setOpen(false)}
      >
        <Shanchu4 />
      </Box>
    )
  }, [setOpen])

  const footer = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          button: {
            width: '160px',
            height: '48px',
            borderRadius: '24px',
            ':nth-of-type(2)': {
              background: '#005EDF',
              color: '#fff',
              marginLeft: '20px',
            },
          },
        }}
      >
        <Button onClick={() => setOpen(false)}>{footerLocale.CANCEL}</Button>
        <Button onClick={handleSubmit}>{footerLocale.SUBMIT}</Button>
      </Box>
    )
  }, [handleSubmit])

  const content = useMemo(() => {
    return (
      <Box
        sx={{
          '.ant-drawer-header': {
            padding: '16px 20px !important',
          },
          '.ant-drawer-body': {
            padding: '28px 10px !important',
          },
        }}
        id='drawer-box'
      >
        <Drawer
          width={660}
          closable={false}
          maskClosable
          open={open}
          title={careerDetails?.tittle || ''}
          extra={extra}
          footer={footer}
          getContainer={() => document.querySelector('#drawer-box') || document.body}
          destroyOnClose
          onClose={() => {
            setOpen(false)
          }}
        >
          <CareersForm
            countryAreaCode={countryAreaCode}
            careerDetails={careerDetails}
            form={form}
          />
        </Drawer>
      </Box>
    )
  }, [open, form, extra, footer, countryAreaCode, careerDetails, setOpen])

  useEffect(() => {
    const htmlDom = document.documentElement
    const bodyDom = document.body
    if (open) {
      htmlDom?.classList?.add('forbidrootscroll')
      bodyDom?.classList?.add('forbidrootscroll')
    } else {
      htmlDom?.classList?.remove('forbidrootscroll')
      bodyDom?.classList?.remove('forbidrootscroll')
    }
  }, [open])

  return {
    open,
    handleOpen,
    content,
  }
}

export default useDrawer
